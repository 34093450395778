var { Frame, NavFrame } = require('framework')

import { portrait } from './portrait.js'
import { fieldErrMsg, thisRemoteError, showModal } from '../common.js'

export async function association(associationApi, ctx) {

  var frameOpts = {
    ...ctx,
    template: 'admin-association',
    label: ctx.nom,
    closable:true,
  }
  var frame = new NavFrame(frameOpts)
  var forms = frame.forms

  function form_load(data) {
    frame.$('.modified span').html(new Date(data.modified).toLocaleString('fr-ca'))
    forms.infoRepondent.load(data)
    forms.profilAssociation.load(data)
  }

  async function refresh() {
    form_load(await associationApi.load())
  }

  async function update(data) {
    await associationApi.update(data)
    .then((modified) => {
      frame.$('.modified span').html(new Date(modified).toLocaleString('fr-ca'))
      frame.$('.modified').css('color', '#00AA00').animate({
        color: 'black',
      }, 500 );
    })
    .catch(thisRemoteError('FormError', (err) => {
      err.form.forEach((field) => {
        forms[field.form].error(fieldErrMsg[field.kind] || field.kind, field.path)
      })
    }))
  }

  frame.forms.infoRepondent.onChange(update)
  frame.forms.profilAssociation.onChange(update)

  frame.$('div.remove button').on('click', showModal({
    message: 'Êtes-vous sûr de vouloir effacer cette fiche?',
    btnOk: 'Effacer',
    onOk: async () => {
      await associationApi.remove()
      frame.close()
    },
  }))

  refresh()

  frame.$('.subtitle').html(ctx.nom)

  return frame
}
