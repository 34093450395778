
var { Frame, NavFrame } = require('framework')

import { fieldErrMsg, debounce, exclusive, thisRemoteError, showModal } from '../common.js'

export async function repondants(usersApi, ctx) {

  const frameOpts = {
    ...ctx,
    template: 'repondants',
    label: 'Répondants',
  }  
  const frame = new NavFrame(frameOpts)
  const form = frame.forms.newUser

  frame.tables.repondants.onClick(async (entry) => {
    if(frame.tables.repondants.$(`tr.frame[docid="${entry._id}"]`).length > 0) {
      return
    }
    var row = frame.tables.repondants.insertFrame('editRepondant', entry._id)
    row.form.load(entry)
    row.show(400)
    row.form.$('a.cancel').on('click', async function () {
      await row.hide(400)
      row.remove()
    })
    row.form.$('a.edit').on('click', exclusive(async function () {
      var data = row.form.pack()
      await usersApi.update(data)
      .then(async (entry) => {
        frame.tables.repondants.update({ entry })
        await row.hide(400)
        row.remove()
        frame.tables.repondants.message(`Le répondant ${entry.email} a été mis à jour.`, entry._id)
      })
      .catch(thisRemoteError('FormError', (err) => {
        err.form.forEach((field) => {
          row.form.error(fieldErrMsg[field.kind] || field.kind, field.path)
        })
      }))
    }))
    row.form.$('a.passwordReset').on('click', async function () {
      await usersApi.passwordReset(entry._id)
      .then(async (entry) => {
        frame.tables.repondants.update({ entry })
        frame.tables.repondants.message(`Le mot de passe pour le répondant ${entry.email} a été réinitialisé. Un courriel a été envoyé.`, entry._id)
      })
    })
    row.form.$('a.effacer').on('click', showModal({
      message: 'Êtes-vous sûr de vouloir effacer ce répondant?',
      btnOk: 'Effacer',
      onOk: async () => {
        await usersApi.remove(entry._id)
        await row.hide(400)
        row.remove()
        frame.tables.repondants.update({ remove: entry._id })
      },
    }))
  })

  async function users_list() {
    var term = frame.$('input.term').val() || ''
    var sortcol = frame.tables.repondants.$('th[sort!=""]')
    var sortfield = sortcol.attr('field')
    var sortorder = (sortcol.attr('sort') == "down") ? 1 : -1
    var sort = {}
    sort[sortfield] = sortorder
    frame.tables.repondants.update(await usersApi.list(term, sort))
  }

  form.$('a.cancel').on('click', exclusive(async function () {
    await form.hide(400)
    frame.$('a.newUser').show(400)
  }))
  form.$('a.create').on('click', exclusive(async function () {
    const data = form.pack()

    await usersApi.update(data)
    .then(async () => {
      form.clear()
      form.$('.focus').focus()
      form.message(`Le répondant ${data.email} a été créé.`)
      await users_list()
    })
    .catch(thisRemoteError('FormError', (err) => {
      err.form.forEach((field) => {
        form.error(fieldErrMsg[field.kind] || field.kind, field.path)
      })
    }))
  }))

  frame.$('a.newUser').on('click', function onNewUser() {
    frame.$('a.newUser').hide(400)
    form.show(400)
    form.$('.focus').focus()
  })

  frame.$('input.term').on('keyup', debounce(users_list, 200))

  frame.tables.repondants.$('th.sort a').on('click', function () {
    var col = $(this).parent()
    var field = col.attr('field')
    var sort = col.attr('sort')
    col.siblings('.sort').attr('sort', '').find('svg').hide()
    sort = (!sort || sort == 'up') ? 'down' : 'up'
    col.attr('sort', sort)
    col.find('svg').hide()
    col.find(`svg.sort-${sort}`).show()
    users_list()
    return false
  })

  await users_list()

  return frame
}
