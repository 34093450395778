var { NavFrame } = require('framework')

import { fieldErrMsg, thisRemoteError, showModal } from '../common.js'

export async function portrait(portraitApi, ctx) {
  const frameOpts = {
    ...ctx,
    template: 'admin-portrait',
    label: ctx.nom,
    closable:true,
  }
  const frame = new NavFrame(frameOpts)
  var forms = frame.forms
  
  function forms_load(portrait) {
    if(portrait.snapshot) {
      forms.infoRepondent.load(portrait.snapshot)
      forms.profilAssociation.load({profil: portrait.snapshot.profilAssociation})
    }
    forms.portrait.load(portrait)
    if(portrait.sent) {
      frame.$('div.unlock').show()
    } else {
      frame.$('div.unlock').hide()
    }
  }

  async function update(data) {
    await portraitApi.update(data)
    .then((modified) => {
      frame.$('.modified span').html(new Date(modified).toLocaleString('fr-ca'))
      frame.$('.modified').css('color', '#00AA00').animate({
        color: 'black',
      }, 500 );
    })
    .catch(thisRemoteError('FormError', (err) => {
      err.form.forEach((field) => {
        var snapshot = /^snapshot\.([^\.]*)\.(.*)$/.exec(field.path)
        if(snapshot) {
          var [, form, path] = snapshot
          forms[form].error(fieldErrMsg[field.kind] || field.kind, path)
        } else {
          forms.portrait.error(fieldErrMsg[field.kind] || field.kind, field.path)
        }
      })
    }))
  }

  forms.portrait.onChange(update)
  forms.infoRepondent.onChange((snapshot) => {
    update({
      snapshot
    })
  })
  forms.profilAssociation.onChange((snapshot) => {
    update({
      snapshot
    })
  })

  frame.$('div.unlock button').on('click', async () => {
    await portraitApi.unlock()
    .then((modified) => {
      frame.$('div.unlock').hide()
      forms.portrait.message("Le formulaire peut de nouveau être modifié par le répondant.")
    })
    .catch((err) => {
      forms.portrait.error(err)
    })    
  })

  frame.$('div.remove button').on('click', showModal({
    message: 'Êtes-vous sûr de vouloir effacer cette fiche?',
    btnOk: 'Effacer',
    onOk: async () => {
      await portraitApi.remove()
      frame.close()
    },
  }))


  var data = await portraitApi.load()
  forms_load(data)
  if(data.modified) {
    frame.$('.modified span').html(new Date(data.modified).toLocaleString('fr-ca'))
  }

  frame.$('.subtitle').html(`${ctx.periode} - ${ctx.nom}`)
  frame.show()

  return frame
}
