
const client = require('clearthru').client

import { login } from './login.js'
import { admin } from './admin/admin.js'
import { respondent } from './respondent/respondent.js'
import * as actions from './actions.js'

(async function () {

  //****************************************************************************
  // General initialisation

  $('body').on('submit', 'form', function(e) {
    e.preventDefault()
  })

  //****************************************************************************
  // websocket RPC

  client.on('restoreFailed', function (err) {
    console.log('restoreFailed: ' + err)
    location.reload(true)
  })
  client.on('connect', function (err) {
    console.log('connect')
  })
  client.on('disconnect', function (err) {
    console.log('disconnect')
  })

  const url = (location.protocol == "https:" ? "wss:" : "ws:") + "//" + location.host
  const boot = await client.init(url)

  //****************************************************************************
  // Login and dispatch logic

  function on_login(api) {
    if(client.getName(api) == 'AdminRoleApi') {
      admin(api, on_logout)
    }
    if(client.getName(api) == 'RespondentRoleApi') {
      respondent(api, on_logout)
    }
  }
  function on_logout() {
    login(boot, on_login)
  }

  //****************************************************************************
  // actions

  var params = new URL(window.location.href).searchParams
  var action = params.get("action")
  if(actions[action]) {
    await actions[action].call(this, boot, params)
  } else {
    on_logout()
  }

})()
.catch(function (err) {
  console.log(err)
})
