var { Frame, NavFrame } = require('framework')

import { thisRemoteError } from '../common.js'

export async function rapport(rapportApi, ctx) {

  const frameOpts = {
    ...ctx,
    template: 'rapport',
    label: 'Rapport',
  }  
  const frame = new NavFrame(frameOpts)

  async function change() {
    var periode = frame.$('select.periode').val()
    var body = await rapportApi.generate(periode)
    //console.log(body)
    Object.keys(frame.tables).forEach((k) => {
      frame.tables[k].update({ body })
    })
    Object.keys(frame.forms).forEach((k) => {
      frame.forms[k].load({ body })
    })
  }

  var periods = await rapportApi.getPeriodes()
  if(periods.length) {
    frame.$('select.periode').empty()
    periods.forEach((period) => {
      frame.$('select.periode').append($(`<option value="${period}">${period}</option>`))  
    })
    frame.$('select.periode').val(periods[0])
    frame.$('select.periode').on('change', change)
  }

  change()

  return frame
}
