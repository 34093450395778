var { Frame } = require('framework')

export async function validateEmail(boot, params) {

  var token = params.get("token")

  var node = $('body')
  var template = 'validateEmail'
  var frame = new Frame({ node, template })

  frame.$('.save').on('click', async function () {
    var { pass1, pass2 } = frame.form.pack()
    if(!pass1 || pass1.length < 8) {
      frame.form.error("Le mot de passe doit être d'une longueur minimale de 8 caractères.", "pass1")
      return
    }
    if(pass1 != pass2) {
      frame.form.error("Les mots de passes ne correspondent pas.", "pass2")
      return
    }
    await boot.setPassword(token, pass1)
    frame.$('div.newpassword').hide()
    frame.$('div.passwordset').show()
  })

  function changePassword() {
    frame.$('div.newpassword').show()
    //frame.$('input.focus').focus()
  }

  frame.show()

  if(token) {
    try {
      await boot.validateEmail(token)
      localStorage.removeItem('credentials')
      changePassword()
    } catch (err) {
      console.log(err)
      frame.$('div.alreadyvalidated').show()
    }
  } else {
    frame.$('div.alreadyvalidated').show()
  }

}
