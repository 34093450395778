
var { Frame, NavFrame } = require('framework')

import { portrait } from './portrait.js'
import { fieldErrMsg, thisRemoteError } from '../common.js'

export async function association(associationApi, ctx) {

  var frameOpts = {
    ...ctx,
    template: 'association',
    label: 'Association',
  }  
  var frame = new NavFrame(frameOpts)
  var forms = frame.forms

  frame.table.onClick(async (entry) => {
    var opened = $('li', frame.subNav).filter((idx, e) => {
      return $(e).attr('label') == entry.periode
    })
    if(opened.length) {
      opened.find('a').click()
    } else {
      var portraitApi = await associationApi.getPortraitApi(entry._id)
      const portraitCtx = {
        node: ctx.node,
        rootNav: ctx.nav,
        nav: frame.subNav,
        periode: entry.periode,
      }
      portrait(portraitApi, portraitCtx, refresh)    
    }
  })

  function form_load(data) {
    frame.$('.modified span').html(new Date(data.modified).toLocaleString('fr-ca'))
    forms.infoRepondent.load(data)
    forms.profilAssociation.load(data)
    frame.table.update({ body: data.portraits })
  }

  async function refresh() {
    form_load(await associationApi.load())
  }

  async function update(data) {
    await associationApi.update(data)
    .then((modified) => {
      frame.$('.modified span').html(new Date(modified).toLocaleString('fr-ca'))
      frame.$('.modified').css('color', '#00AA00').animate({
        color: 'black',
      }, 500 );
    })
    .catch(thisRemoteError('FormError', (err) => {
      err.form.forEach((field) => {
        forms[field.form].error(fieldErrMsg[field.kind] || field.kind, field.path)
      })
    }))
  }

  frame.forms.infoRepondent.onChange(update)
  frame.forms.profilAssociation.onChange(update)

  frame.$('a.fillPortrait').on('click', async function () {
    frame.$('table.portraits tr.entry:first').click()
  })

  /*
  frame.$('a.save').on('click', async function () {
    await associationApi.update({ 
      infoRepondent: forms.infoRepondent.pack().infoRepondent, 
      profil: forms.profilAssociation.pack().profil,
    })
    .then((data) => {
      form_load(data)
      forms.profilAssociation.message("Les données ont été sauvegardé.")
    })
    .catch(thisRemoteError('FormError', (err) => {
      err.form.forEach((field) => {
        forms[field.form].error(fieldErrMsg[field.kind] || field.kind, field.path)
      })
      $(ctx.node).animate({
        scrollTop: frame.$('form p.feedback').first().parents('div.field').first().position().top
      }, 1000)
    }))
  })
  */

  refresh()

  return frame
}
