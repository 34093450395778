
export const fieldErrMsg = {
  'required': 'Ce champ est requis.',
  'unique': 'Cette valeur existe déjà.',
  'Date': 'Ce champ doit être une date. (YYYY-MM-DD)',
  'Number':'Ce champ doit être un nombre.'
}

export function ctxify(obj) {
  const ctx = (obj instanceof jQuery) ? obj : $(obj)
  return function (sel) {
    return (sel) ? ctx.find(sel) : ctx
  }
}

export function randomId() {
  return ("00000000000" + Math.random().toString(36).substring(2)).substr(-11,11)
}

export function debounce(func, wait, immediate) {
  var timeout
  return function() {
    var context = this
    var args = arguments
    var later = function() {
      timeout = null
      if (!immediate) {
        func.apply(context, args)
      }
    }
    var callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) {
      func.apply(context, args)
    }
  }
}

export function exclusive(func) {
  var pending
  return function (...args) {
    if(pending) {
      return
    }
    pending = Promise.resolve()
    .then(() => {
      return func(...args)
    })
    .finally(() => {
      pending = null
    })
  }
}

export function thisError(name, cb) {
	if(typeof name === 'string') {
		name = [name]
	}
	return function (err) {
		if(name.includes(err.name)) {
			return cb(err)
		} else {
			throw err
		}		
	}
}

export function thisRemoteError(name, cb) {
	if(typeof name === 'string') {
		name = [name]
	}
	return function (err) {
		if(err.name == 'RemoteError' && name.includes(err.remote.name)) {
			return cb(err.remote)
		} else {
			throw err
		}		
	}
}

export function showModal({
  title = 'Confirmation',
  message = '',
  btnOk = 'OK',
  btnCancel = 'Annuler',
  onOk = () => {},
  onCancel = () => {}
}) {
  var modal = $(`<div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <section class="modal-card-head">
        <p class="modal-card-title">${title}</p>
      </section>
      <section class="modal-card-body">
        ${message}
      </section>
      <footer class="modal-card-foot">
        <button class="ok button is-danger">${btnOk}</button>
        <button class="cancel button">${btnCancel}</button>
      </footer>
    </div>
    <button class="modal-close is-large" aria-label="close"></button>
  </div>
  `)
  return () => {
    $('body').append(modal)
    modal.find(".modal-background,button").on('click', () => {
      modal.remove()
    })
    modal.find("button.ok").on('click', onOk)
    modal.find("button.cancel").on('click', onCancel)
  }
}
