var { Frame, NavFrame } = require('framework')

import { fieldErrMsg, debounce, thisRemoteError } from '../common.js'

import { portrait } from './portrait.js'

export async function portraits(portraitsApi, ctx) {

  const frameOpts = {
    ...ctx,
    template: 'portraits',
    label: 'Portraits',
  }  
  const frame = new NavFrame(frameOpts)

  frame.table.onClick(async (entry) => {
    var nom = entry.snapshot.profilAssociation && entry.snapshot.profilAssociation.nom || '(Non défini)'
    var opened = $('li', frame.subNav).filter((idx, e) => {
      return $(e).attr('label') == nom
    })
    if(opened.length) {
      opened.find('a').click()
    } else {
      var portraitApi = await portraitsApi.getPortraitApi(entry._id)
      const portraitCtx = {
        node: ctx.node,
        rootNav: ctx.nav,
        nav: frame.subNav,
        nom,
        periode: entry.periode,
      }
      portrait(portraitApi, portraitCtx)    
    }
  })

  async function change() {
    var periode = frame.$('select.periode').val()
    var terms = frame.$('input.terms').val() || ''
    frame.tables.portraits.update(await portraitsApi.list(periode, terms))
  }

  frame.$('input.terms').on('keyup', debounce(change, 200))

  var periods = await portraitsApi.getPeriodes()
  if(periods.length) {
    frame.$('select.periode').empty()
    periods.forEach((period) => {
      frame.$('select.periode').append($(`<option value="${period}">${period}</option>`))  
    })
    frame.$('select.periode').val(periods[0])
    frame.$('select.periode').on('change', change)
  }

  frame.on('show', change)

  return frame
}
