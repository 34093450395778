var { client } = require('clearthru')
var { Frame } = require('framework')

import { rapport } from './rapport.js'
import { repondants } from './repondants.js'
import { portraits } from './portraits.js'
import { associations } from './associations.js'

export async function admin(adminApi, on_logout) {

  $('body').addClass('has-navbar-fixed-top')

  const node = $('body')
  const template = 'main'
  const frame = new Frame({ node, template })

  frame.$('.navbar-menu a.logout').on('click', function () {
    localStorage.removeItem('credentials')
    client.unlink(adminApi)
    frame.remove()
    on_logout()
  })

  const ctx = {
    node: frame.$('div.frames'),
    nav: frame.$('ul.menu-list.general'),
  }

  ;(await rapport(await adminApi.getRapportApi(), ctx)).show()
  ;(await portraits(await adminApi.getPortraitsApi(), ctx))//.show()
  await associations(await adminApi.getAssociationsApi(), ctx)
  await repondants(await adminApi.getUsersApi(), ctx)

  frame.$('nav a.username').html("Administrateur")

  frame.show()

}
