var { client } = require('clearthru')
var { Frame, NavFrame } = require('framework')

import { association } from './association.js'

export async function respondent(respondentApi, on_logout) {

  $('body').addClass('has-navbar-fixed-top')

  const node = $('body')
  const template = 'main'
  const frame = new Frame({ node, template })

  frame.$('.navbar-menu a.logout').on('click', function () {
    localStorage.removeItem('credentials')
    client.unlink(respondentApi)
    frame.remove()
    on_logout()
  })

  const ctx = {
    node: frame.$('div.frames'),
    nav: frame.$('ul.menu-list.general'),
  }
  new NavFrame({
    ...ctx,
    template: 'miseensituation',
    label: 'Mise en situation',
  }).show()

  new NavFrame({
    ...ctx,
    template: 'outils',
    label: 'Outils à télécharger',
  })

  ;(await association(await respondentApi.getAssociationApi(), ctx))//.show()

  frame.$('nav a.username').html(await respondentApi.getUserName())

  frame.show()
}
