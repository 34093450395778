
var { NavFrame } = require('framework')

import { fieldErrMsg, thisRemoteError } from '../common.js'

export async function portrait(portraitApi, ctx, associationRefresh) {
  const frameOpts = {
    ...ctx,
    template: 'portrait',
    label: ctx.periode,
    closable:true,
  }
  const frame = new NavFrame(frameOpts)

  var refresh = () => {
    associationRefresh()
    refresh = () => {}
  }

  frame.form.onChange(async (data) => {
    await portraitApi.update(data)
    .then((modified) => {
      refresh()
      frame.$('.modified span').html(new Date(modified).toLocaleString('fr-ca'))
      frame.$('.modified').css('color', '#00AA00').animate({
        color: 'black',
      }, 500 );
    })
    .catch(thisRemoteError('FormError', (err) => {
      console.log(err)
      err.form.forEach((field) => {
        frame.form.error(fieldErrMsg[field.kind] || field.kind, field.path)
      })
    }))
    .catch((err) => {
      console.log(err)
    })
  })

  /*frame.$('a.save').on('click', async function () {
    var data = frame.form.pack()
    //console.log(data)
    await portraitApi.update(data)
    .then((data) => {
      frame.form.load(data)
      frame.form.message("Les données ont été sauvegardé.")
      return associationRefresh()
    })
    .catch(thisRemoteError('FormError', (err) => {
      err.form.forEach((field) => {
        frame.form.error(fieldErrMsg[field.kind] || field.kind, field.path)
      })
      $(ctx.node).animate({
        scrollTop: frame.$('form p.feedback').first().parents('div.field').first().position().top
      }, 1000)
    }))
  })*/

  frame.$('a.send').on('click', async function () {
    var modal = $(`<div class="modal is-active">
      <div class="modal-background"></div>
      <div class="modal-card">
        <section class="modal-card-head">
          <p class="modal-card-title">Confirmation</p>
        </section>
        <section class="modal-card-body">
          Une fois le formulaire envoyé, il ne sera plus possible de le modifier. Êtes-vous sûr de vouloir procéder?
        </section>
        <footer class="modal-card-foot">
          <button class="ok button is-danger">Envoyer</button>
          <button class="cancel button">Annuler</button>
        </footer>
      </div>
      <button class="modal-close is-large" aria-label="close"></button>
    </div>
    `)
    $('body').append(modal)
    modal.find(".modal-background,button").on('click', () => {
      modal.remove()
    })
    modal.find("button.ok").on('click', async () => {
      await portraitApi.send()
      .then((data) => {
        frame.form.readonly(data.sent)
        if(data.sent) {
          frame.$('a.send').hide()
        }
        frame.form.message("Le formulaire a été envoyé.")
        return associationRefresh()
      })
      .catch(thisRemoteError('FormError', (err) => {
        console.log(err)
        err.form.forEach((field) => {
          frame.form.error(fieldErrMsg[field.kind] || field.kind, field.path)
        })
        $(ctx.node).animate({
          scrollTop: $(ctx.node).scrollTop() + frame.$('form p.feedback').first().parents('div.field').first().position().top - 200
        }, 1000)
      }))
    })    
  })


  var data = await portraitApi.load()
  frame.form.load(data)
  frame.form.readonly(data.sent)
  if(data.sent) {
    frame.$('a.send').hide()
  }
  frame.$('.modified span').html(data.modified ? new Date(data.modified).toLocaleString('fr-ca') : 'Jamais')


  frame.$('.subtitle').html(ctx.periode)
  frame.show()

  return frame
}
