var { Frame, NavFrame } = require('framework')

import { fieldErrMsg, debounce, thisRemoteError } from '../common.js'

import { association } from './association.js'

export async function associations(associationsApi, ctx) {

  const frameOpts = {
    ...ctx,
    template: 'associations',
    label: 'Associations',
  }  
  const frame = new NavFrame(frameOpts)

  frame.table.onClick(async (entry) => {
    var nom = entry.profil.nom
    var opened = $('li', frame.subNav).filter((idx, e) => {
      return $(e).attr('label') == nom
    })
    if(opened.length) {
      opened.find('a').click()
    } else {
      var associationApi = await associationsApi.getAssociationApi(entry.repondent.email)
      const associationCtx = {
        node: ctx.node,
        rootNav: ctx.nav,
        nav: frame.subNav,
        nom,
      }
      ;(await association(associationApi, associationCtx)).show()
    }
  })

  async function change() {
    var terms = frame.$('input.terms').val()
    var list = await associationsApi.list(terms)
    frame.tables.associations.update(list)
  }

  frame.$('input.terms').on('keyup', debounce(change, 200))

  frame.on('show', change)

  return frame
}
